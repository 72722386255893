<template lang="pug">
    #Box
        ul
            li(v-for="item in boxes")
                a(:href="item.link")
                    .img
                        img(:src="item.image").image
                    h3 {{ item.title }}
                    p {{ item.text }}
                    span {{ item.call }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "box",
    props,
    computed: {
        boxes() {
            return this.contents.content.map(item => ({
                ...item,
                image: this.getBoxImage(item)
            }))
        },
        news() {
            return this.$store.state.news.map(news => ({
				...news,
				translations: news.translations.map(translation => ({
					thumb: translation.thumb ? `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${translation.thumb.id}` : ``,
				}))	
			}))
        },
        magazines() {
            return this.$store.state.magazines
        }
    },
    methods: {
        getBoxImage(item) {
            switch(item.type) {
                case 'image': return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`;
                case 'lastMagazine': {
                    if (this.magazines.length === 0) return '';
                    // const editions = this.magazines.map(magazine => magazine.edition);
                    // const lastEdition = Math.max(...editions)
                    // const lastMagazine = this.magazines.find(magazine => magazine.edition === lastEdition)

                    let lastMagazine;
                    for (let magazine of this.magazines) {
                        if (!(lastMagazine?.edition > magazine.edition)) lastMagazine = magazine;
                    }
                    return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${lastMagazine.image.id}`;
                }
                case 'lastNews': {
                    if (this.news.length === 0) return '';
                    return this.news[0].translations[0].thumb;
                }
            }
        }
    },
    created() {
        this.$store.dispatch('fetchNews', { skip: 0, take: 1 })
        this.$store.dispatch('fetchMagazines')
    },
}
</script>

<style lang="stylus" scoped src="./Box.styl"></style>
